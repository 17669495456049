<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="mb-6">
          <eCommSitesOwned></eCommSitesOwned>
        </div>
        <div class="mt-8">
          <eCommTextOnly></eCommTextOnly>
        </div>
        <div class="mt-8">
          <eCommSitesBarChart></eCommSitesBarChart>
        </div>
        <div class="mt-8">
          <ECommSitesTextOnly></ECommSitesTextOnly>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import eCommSitesOwned from './eCommSitesOwned.vue';
import eCommTextOnly from './eCommCogsProductTextOnly.vue';
import eCommSitesBarChart from './eCommSitesBarChart.vue';
import ECommSitesTextOnly from './eCommCogsChannelText.vue';

export default {
  components: {
    eCommSitesBarChart,
    ECommSitesTextOnly,
    eCommSitesOwned,
    eCommTextOnly
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'COGS ◾ E-Commerce'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
